// api.js

import axios from 'axios';

export const mainQuery = async (queryObject) => {
    if (!queryObject) return;

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_DOMAIN_URI}/graphql`,
            {
                query: queryObject
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
        return undefined; // Return undefined or handle the error as needed
    }
};
