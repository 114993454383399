// templates/home.js
import React from 'react';
import { Link } from 'react-router-dom';

const notFound = () => {
    return (
      <section className="xl:px-0 px-4">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                  <h1 className="mb-4 tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
                  <p className="mb-4  tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
                  <p className="mb-4  font-light">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                  <div className='ContentButton my-4 mx-auto text-center flex justify-center'>
                        <Link to='/' className='btn btn-open'>Back to Homepage</Link>
                    </div>
              </div>   
          </div>
      </section>
    );
};

export default notFound;