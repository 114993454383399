import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './resources/css/index.css';
import Archive from './templates/archive';
import Home from './templates/home';
import Page from './templates/singlePage';
import Post from './templates/singlePost';
import PreviewMode from './templates/PreviewMode';
import MainMenu from './templates/parts/MainMenu';
import Footer from './templates/parts/Footer';
import NotFound from './templates/notFound';
import { fetchPages, fetchPosts } from './queries/getPages';
import {GeneralSettings} from './queries/getGeneralSettings';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './templates/parts/ScrollToTop';


const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
    const [pages, setPages] = useState([]);
    const [posts, setPosts] = useState([]);
    const [cases, setCases] = useState([]);
    const [siteData, setSiteData] = useState([]);

    useEffect(() => {
        const getPages = async () => {
            try {
                const response = await fetchPages();
                setPages(response);
            } catch (error) {
                console.error(error);
            }
        };
        const getPosts = async () => {
            try {
                const response = await fetchPosts(1, 100);
                setPosts(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        const getCases = async () => {
            try {
                const response = await fetchPosts(1, 100, 'cases');
                setCases(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        const getSiteData = async () => {
            try {
                const response = await GeneralSettings();
                setSiteData(response);
            } catch (error) {
                console.error(error);
            }
        };

        getSiteData();
        getPosts();
        getCases();
        getPages();
    }, []);

    
    return (
        <BrowserRouter>

            <HelmetProvider>
                <Helmet>
                    <title>{siteData.Title ?? process.env.REACT_APP_DOMAIN_URI}</title>
                    <meta name="description" content={siteData.Description ?? ''} />
                    {siteData.Favicon && siteData.Favicon.data && (
                        <link rel="icon" href={`${process.env.REACT_APP_DOMAIN_URI}${siteData.Favicon.data.attributes.url ?? ''}`} />
                    )}
                </Helmet>
            </HelmetProvider>

            <MainMenu logo={`${JSON.stringify(siteData.Logo) ?? false}`} />
            <Routes>
                {pages.map((page) => (
                    <Route key={page.attributes.slug} path={page.attributes.slug} element={<Page slug={page.attributes.slug} />} />
                ))}
                {posts.map((post) => (
                    <Route key={post.attributes.slug} path={post.attributes.slug} element={<Post slug={post.attributes.slug} />} />
                ))}
                {cases.map((post) => (
                    <Route key={post.attributes.slug} path={post.attributes.slug} element={<Post slug={post.attributes.slug} postType='case' />} />
                ))}
                <Route path="/" exact element={<Home />} />
                <Route path="/kennisbank" element={<Archive />} />
                <Route path="/previewMode/*" element={<PreviewMode />} />
                {pages.length > 0 && posts.length > 0 && (
                    <Route path="*" element={<NotFound />} />
                )}
            </Routes>
            <Footer />
            <ScrollToTop />
        </BrowserRouter>
    );
};

root.render(
    <React.StrictMode>
        <div className="w-full max-w-full h-full">
            <App />
        </div>
    </React.StrictMode>
);