import { mainQuery } from './mainQuery';

export const fetchPosts = async (page = 1, pageSize = 8, postType = 'posts') => {
  const queryObject = `
  query {
    ${postType}(pagination: { page: ${page}, pageSize: ${pageSize} }) {
      data {
        attributes {
          slug
          Title
          featuredimage {
            data {
              attributes {
                url
              }
            }
          }
          publishedAt
          categories {
            data {
              attributes {
                Title
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
        }
      }
    }
  }
  `;
  const response = await mainQuery(queryObject);
  return response.data.data[`${postType}`];
};

// queries/getPages.js
export const fetchPages = async () => {
  const queryObject = `
  query {
      pages {
          data {
              attributes {
                Title
                slug
              }
          }
      }
  }
  `;

  const response = await mainQuery(queryObject);

  return response.data.data.pages.data;
};
