import { mainQuery } from './mainQuery';

export const fetchMainMenu = async () => {
  const queryObject = `
    query{
      menusMenu(id: 1){
        data{
          attributes{
            items{
              data{
                attributes{
                  title
                  url
                  target
                  order
                  parent {
                    data {
                      id
                    }
                  }
                }
                id
              }
            } 
          }
        }
      }
    }
  `;

  const response = await mainQuery(queryObject);
  return response.data;
};