// templates/home.js
import React from 'react';

export const ContentBlock = ({ component }) => {
    if (component.__typename === 'ComponentContentContent') {                           
        if(component.Content){
            return (
                <div style={{backgroundColor: `${component.Background_color}`}} dangerouslySetInnerHTML={{__html: component.Content}} className='contentBlock p-4' />
            );
        }
    }

};

