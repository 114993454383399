import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Grid, Pagination } from 'swiper/modules';

export const ImageSlider = ({ component }) => {
    if (component.__typename === 'ComponentContentSlider') {

        return (
            <div>
                <Swiper
                    spaceBetween={parseInt(component.spaceBetween) ?? 30}
                    slidesPerView={parseInt(component.slidesPerView) ?? 1}
                    slidesPerGroup={parseInt(component.slidesPerView) ?? 1}
                    grid={{
                        rows: component.Rows ?? 1,
                        fill: 'row',
                    }}
                    modules={[Grid, Pagination]}
                    loop={component.Loop ?? false}
                    loopAddBlankSlides={false}
                    className='!-z-10'
                    pagination={{
                        el: '.custom-swiper-pagination',
                        clickable: true,
                        renderBullet: (index, className) => {
                            return '<span class="' + className + '"></span>';
                        },
                    }}
                >
                    {component.Images.data.map((image, index) => (
                        <SwiperSlide key={index} className="py-2">
                            <div
                                style={{ background: component.Background }}
                                className="aspect-square p-3 shadow-mixcom"
                            >
                                <img
                                    src={`${process.env.REACT_APP_DOMAIN_URI}${image.attributes.url}`}
                                    alt={image.attributes.alternativeText}
                                    className="w-full object-cover h-fit"
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="custom-swiper-pagination mx-auto my-6 flex translate-x-0 gap-2"></div>
            </div>
        );
    }
    return null;
};