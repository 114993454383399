// templates/home.js
import React, { useState, useEffect } from 'react';
import { fetchComponements } from '../queries/getComponements';
import { DynamicContent } from '../templates/parts/dynamicContent';
import {LoadingPost} from '../templates/parts/loadingComponements';
import { Helmet } from 'react-helmet';



const Post = ({ slug, postType = 'post' }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetchComponements(postType,slug);
                setData(response);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        getData();
    }, [slug, postType]); // Added postType to the dependency array

    if (loading) {
        return <LoadingPost />;
    }
    if (error) {
        console.error(error.message);
        return <LoadingPost />;
    }
    
    return (
        <>
            {data.SEO && (
                <Helmet>
                    <title>{data.SEO.metaTitle}</title>
                    <meta name="description" content={data.SEO.metaDescription} />
                    <meta name="keywords" content={data.SEO.keywords} />
                    {data.SEO.metaImage && data.SEO.metaImage.data && (
                        <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN_URI}${data.SEO.metaImage.data.attributes.url}`} />
                    )}
                    <meta name="robots" content={data.SEO.metaRobots} />
                    <meta name="viewport" content={data.SEO.metaViewport} />
                    {data.SEO.canonicalURL && <link rel="canonical" href={data.SEO.canonicalURL} />}
                    {data.SEO.metaSocial && data.SEO.metaSocial.map((social, index) => (
                        <React.Fragment key={index}>
                        <meta property={`og:title`} content={social.title} />
                        <meta property={`og:description`} content={social.description} />
                        {social.image && social.image.data && (
                            <meta property={`og:image`} content={`${process.env.REACT_APP_DOMAIN_URI}${social.image.data.attributes.url}`} />
                        )}
                        </React.Fragment>
                    ))}
                    {data.SEO.structuredData && (
                        <script type="application/ld+json">
                        {JSON.stringify(data.SEO.structuredData)}
                        </script>
                    )}
                </Helmet>
            )}
            <div className="post">
                <div className="site-content max-w-post-content  xl:px-0 px-4 mx-auto my-14">
                    <h1 className='text-center'>{data.Title}</h1>
                    <DynamicContent data={data} />
                </div>
            </div>
        </>
    );
};

export default Post;