import React, { useEffect, useState } from 'react';
import { fetchPosts } from '../queries/getPages';
import { Link } from 'react-router-dom';
import {Error,LoadingArchive} from '../templates/parts/loadingComponements';

function DynamicContent() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchPosts(1, 4 , 'cases');
        setData(data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

  
      
  
    getData();
  }, []);


  if (loading) {
    return <LoadingArchive />;
}
if (error) {
    return <Error message={error.message} />;
}

  return (
    <div className="App max-w-inner-content mx-auto ">
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-5'>
          {data.map((page, index) => ( 
            <Link key={index} to={'/'+page.attributes.slug} className='h-full my-0' >
              <div key={index} className="overflow-hidden bg-white h-full shadow-mixcom">
                {page.attributes.featuredimage && page.attributes.featuredimage.data && (
                  <img 
                    src={`${process.env.REACT_APP_DOMAIN_URI}${page.attributes.featuredimage.data.attributes.url}`} 
                    alt={page.attributes.Title} 
                    className=" max-h-[420px] w-full object-cover h-fit" 
                  />
                )}
                <div className="p-4">
                  <p className="mb-1 font-light text-sm italic text-gray-600 text-black !mt-0">
                    {new Date(page.attributes.publishedAt).toLocaleDateString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' })}
                    <span> | </span>
                    {page.attributes.categories.data.map((category, catIndex) => (
                      <span key={catIndex}>{category.attributes.Title}{catIndex < page.attributes.categories.data.length - 1 ? ', ' : ''}</span>
                    ))}
                  </p>
                  <h5 className=" font-bold text-black !mt-0">{page.attributes.Title}</h5>
                </div>
              </div>
            </Link>
          ))}
        </div>
    </div>
  );
}

export default DynamicContent;