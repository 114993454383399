import React, { useEffect, useState } from 'react';
import { fetchPosts } from '../queries/getPages';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoadingArchive } from '../templates/parts/loadingComponements';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Archive() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = useQuery();
  const searchQuery = query.get('s');
  const navigate = useNavigate();
  const pageSize = 8;

  const initialPage = parseInt(query.get('page')) || 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await fetchPosts(currentPage, pageSize);
        setData(response.data);
        setTotalPages(response.meta.pagination.pageCount);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    getData();
  }, [currentPage]);

  const filterPosts = (posts, query) => {
    if (!query) {
      return posts;
    }
    return posts.filter((post) => 
      post.attributes.Title.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}${searchQuery ? `&s=${searchQuery}` : ''}`);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  if (loading) {
    return <LoadingArchive />;
  }
  if (error) {
    console.error(error.message);
    return <LoadingArchive />;

  }

  const filteredData = filterPosts(data, searchQuery);

  return (
    <div className="App max-w-inner-content mx-auto xl:px-0 px-4">
      <header className="App-header">
        <h1 className="mb-4">Artikelen</h1>
      </header>
      <div className='body archive grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 mt-11'>
        {filteredData.length > 0 ? (
          filteredData.map((page, index) => (
            <Link key={index} to={'/' + page.attributes.slug}>
              <div key={index} className="overflow-hidden bg-white aspect-square shadow-mixcom">
                {page.attributes.featuredimage && page.attributes.featuredimage.data && (
                  <img 
                    src={`${process.env.REACT_APP_DOMAIN_URI}${page.attributes.featuredimage.data.attributes.url}`} 
                    alt={page.attributes.Title} 
                    className="w-full object-cover h-1/2" 
                  />
                )}
                <div className="p-4">
                  <p className="mb-1 font-light text-sm italic text-gray-600 text-black !mt-0">
                    {new Date(page.attributes.publishedAt).toLocaleDateString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' })}
                    <span> | </span>
                    {page.attributes.categories.data.map((category, catIndex) => (
                      <span key={catIndex}>{category.attributes.Title}{catIndex < page.attributes.categories.data.length - 1 ? ', ' : ''}</span>
                    ))}
                  </p>
                  <h5 className="font-bold text-black !mt-0">{page.attributes.Title}</h5>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p className="">Geen artikelen gevonden.</p>
        )}
      </div>
      <div className="pagination flex w-full items-center gap-3 justify-center my-5">
        <button className=' btn-open p-0 flex justify-center content-center flex-wrap rounded-full h-10 w-10' onClick={handlePreviousPage} disabled={currentPage === 1}>
          <svg className='' xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#FFFFFF"><path d="M240-240v-480h80v480h-80Zm440 0L440-480l240-240 56 56-184 184 184 184-56 56Z"/></svg>
        </button>
        <p className='font-black'>{`${currentPage} / ${totalPages}`}</p>
        <button className='btn-open p-0 flex justify-center content-center flex-wrap rounded-full h-10 w-10'  onClick={handleNextPage} disabled={currentPage === totalPages}>
          <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#FFFFFF"><path d="m280-240-56-56 184-184-184-184 56-56 240 240-240 240Zm360 0v-480h80v480h-80Z"/></svg>
        </button>
      </div>
    </div>
  );
}

export default Archive;