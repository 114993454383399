// templates/home.js
import React from 'react';
export const ContentHero = ({ component }) => {
    if (component.__typename === 'ComponentContentHero') {                           
        return (
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-20 justify-center '>
                <div className='flex flex-col gap-7 justify-center '>
                <h1 className='text-white text-7xl font-bold'>{component.title}</h1>
                <p className='text-3xl text-white font-bold'>{component.tekst}</p>
                </div>
                {component.images.data.map((image, imgIndex) => (
                    <img
                        key={imgIndex}
                        src={`${process.env.REACT_APP_DOMAIN_URI}${image.attributes.url}`} 
                        alt={image.attributes.alternativeText}
                        width={image.attributes.width}
                        height={image.attributes.height}
                    />
                ))}
            </div>
        );
    }
};

