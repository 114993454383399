import { useState, useEffect } from 'react';
import { Outlet} from 'react-router-dom';
import { fetchFooterWidgets, fetchFooterBottom } from '../../queries/getFooter';
import {LoadingFooter} from '../../templates/parts/loadingComponements';
const Footer = () => {
    const [widgets, setWidgets] = useState(null);
    const [bottomFooter, setBottomFooter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getWidgets = async () => {
            try {
                const response = await fetchFooterWidgets();
                setWidgets(response.data.footer.data.attributes.Widget);

                const responseBottom = await fetchFooterBottom();
                setBottomFooter(responseBottom.data.footerBottom.data.attributes.Widget);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        getWidgets();

    
    }, []);

    
    if (loading) {
        return <LoadingFooter />;
    }
    if (error) {
        console.error(error.message);
        return <LoadingFooter />;
    }
    const getGridColsClass = (length) => {
        if (!length) return 'md:grid-cols-3';
        switch (length) {
          case 1:
            return 'md:!grid-cols-1';
          case 2:
            return 'md:!grid-cols-2';
          case 3:
            return 'md:!grid-cols-3';
          default:
            return `md:!grid-cols-${length}`;
        }
      };

    return (
        <>
            <div className='bg-white pt-9 pb-0 footer  xl:px-0 px-4 mt-14'>
                <div className='max-w-inner-content mx-auto '>
                    <div className={`footer-colums my-0 grid gap-7 grid-cols-1 ${getGridColsClass(widgets.length)} `}>
                        {widgets.map((item, index) => (
                            <div key={index} dangerouslySetInnerHTML={{__html: item.Content}} className='FooterWidget text-black' />
                        ))}
                    </div>
                    <hr className='h-[2px] bg-[#979797]' />
                    <div className={`my-0 flex flex-row justify-between `}>
                        {bottomFooter.map((item, index) => (
                            <div key={index} dangerouslySetInnerHTML={{__html: item.Content}} className='FooterWidget text-black' />
                        ))}
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default Footer;

