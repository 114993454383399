import React, { useState } from 'react';
import {formQuery} from '../queries/formQuery';

export const Form = ({ component }) => {
  const [formData, setFormData] = useState({});
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await formQuery(component.Label, formData );
        setSuccess(true);
    } catch (error) {
        console.error('Error submitting form:', error);
        setSuccess(false);
    }
  };

  function renderSwitch(field) {
    switch (field.Type) {
      case 'textfield':
        return (
          <input
            type="text"
            name={`${field.Label}`}
            onChange={handleChange}
            className="block p-3 w-full text-sm text-black bg-white rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
            placeholder={field.Placeholder}
            required={field.Required}
          />
        );
      case 'e_mail':
        return (
          <input
            type="email"
            name={`${field.Label}`}
            onChange={handleChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
            placeholder={field.Placeholder}
            required={field.Required}
          />
        );
      case 'upload':
        return (
          <input
            type="file"
            name={`${field.Label}`}
            onChange={handleChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
            required={field.Required}
          />
        );
      case 'textarea':
        return (
          <textarea
            name={`${field.Label}`}
            rows="6"
            onChange={handleChange}
            className="block p-2.5 w-full text-sm text-black bg-white rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
            placeholder={field.Placeholder}
            required={field.Required}
          ></textarea>
        );
      default:
        return (
          <input
            type="text"
            name={`${field.Label}`}
            onChange={handleChange}
            className="block p-3 w-full text-sm text-black bg-white rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
            placeholder={field.Placeholder}
            required={field.Required}
          />
        );
    }
  }

  return (
    <section>
      <div className="mx-auto max-w-screen-md">
        <h2 className="mb-4 text-center">{component.Label}</h2>
        <p className="mb-8 lg:mb-16 font-light text-center sm:text-xl">{component.Description}</p>
        {success && (
          <div className="bg-green-100 border text-center border-green-400 text-green-700 px-4 py-3 rounded-lg mb-4">
            {component.Succesmessage ?? 'Your message has been sent successfully!'}
          </div>
        )}
        {!success && (
            <form onSubmit={handleSubmit} className="space-y-8">
                {component.Field.map((field, fieldIndex) => (
                <div key={fieldIndex}>
                    <label className="block mb-2 text-sm font-medium text-white">{field.Label}</label>
                    {renderSwitch(field)}
                </div>
                ))}
                <button type="submit" className="btn btn-open">Verstuur</button>
            </form>
        )}
        
      </div>
    </section>
  );
};