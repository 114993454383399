// templates/home.js
import React from 'react';
import { Link } from 'react-router-dom';

export const ContentButton = ({ component }) => {
    if (component.__typename === 'ComponentContentButton') {          
        var Align = '';
        if(component.Alignment.toLowerCase() === 'center') Align = 'justify-center';
        if(component.Alignment.toLowerCase() === 'right') Align = 'justify-end';
        if(component.Alignment.toLowerCase() === 'left') Align = 'justify-start';
        return (
            <div className={`ContentButton my-4 flex ${Align}`}>
                <Link to={component.ButtonUrl} target={`_${component.Target}`} className={`btn btn-${component.Styles.toLowerCase()}`}>{component.ButtonText}</Link>
            </div>
        );
    }

};

