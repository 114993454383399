// templates/home.js
import React, { useState, useEffect } from 'react';
import { fetchComponements } from '../queries/getComponements';
import { DynamicContent } from './parts/dynamicContent';
import { useLocation } from 'react-router-dom';
import {Loading} from '../templates/parts/loadingComponements';


const PreviewMode = ({ slug }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const PreviewType = params.get('type');
    const PreviewSlug = params.get('slug');
    const previewID = params.get('previewID');
    
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetchComponements(PreviewType, PreviewSlug, previewID);
                setData(response);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        getData();
    }, [slug, PreviewSlug, PreviewType, previewID]); // Added missing dependencies

    if (loading) {
        return <Loading />;
    }
    if (error) {
        console.error(error.message);
        return <Loading />;

    }
    return (
        <div className="page">
            <div className="site-content max-w-inner-content mx-auto xl:px-0 px-4 my-14">
                <h1>{data.Title}</h1>
                <DynamicContent data={data} />
            </div>
        </div>
    );
};

export default PreviewMode;