import { mainQuery } from './mainQuery';

export const GeneralSettings = async () => {
  const queryObject = `
    query {
      generalStetting {
        data {
          attributes {
            Title
            Description
            Logo {
              data {
                attributes {
                  width
                  height
                  url
                  alternativeText
                }
              }
            }
            Favicon {
              data {
                attributes {
                  width
                  height
                  url
                  alternativeText
                }
              }
            }
          }
        }
      }
    }
  `;
  const response = await mainQuery(queryObject);
  return response.data.data.generalStetting.data.attributes;
};