import React, { useState, useEffect } from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { fetchMainMenu } from '../../queries/getMainMenu';
import { LoadingMenu } from '../../templates/parts/loadingComponements';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const organizeMenuItems = (items) => {
    const menu = {};
    items.forEach(item => {
        if (!item.attributes.parent.data) {
            menu[item.id] = { ...item, children: [] };
        } else {
            const parentId = item.attributes.parent.data.id;
            if (!menu[parentId]) {
                menu[parentId] = { children: [] };
            }
            menu[parentId].children.push(item);
        }
    });
    return menu;
};

const MainMenu = ({logo}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [keyword, setKeyword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetchMainMenu();
                const organizedData = organizeMenuItems(response.data.menusMenu.data.attributes.items.data);
                setData(Object.values(organizedData).filter(item => !item.attributes.parent.data));
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        getData();
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            navigate(`/kennisbank?s=${encodeURIComponent(keyword.trim())}`);
            setSearchOpen(false); // Close search input after search
        }
    };

    const renderMenuItems = (items, isChild) => {
        return (
            <ul className={`flex ${isChild ? 'mt-0' : 'mt-24 lg:mt-0'}   gap-7 flex-col lg:flex-row  gap- main-menu justify-end my-0 lg:items-center items-end lg:py-2 px-4 lg:static absolute lg:bg-white bg-transparent w-full lg:w-auto left-0 top-auto`}>
                {items.map((item, menuIndex) => (
                    <li key={item.id} style={ menuOpen ? { transitionDelay: (0 +  item.attributes.order * 50) + 'ms',  order: item.attributes.order } : { transitionDelay: (500 + items.length -  item.attributes.order * 50) + 'ms', order: item.attributes.order } } className={`${item.children && item.children.length > 0 ? 'menu-wrapper-has-children ' : ''} hover:cursor-pointer list-none my-0 relative menu-item-wrapper duration-300 transition-all -mr-96 ${menuOpen ? 'mr-8 open' : 'close'} lg:mr-0`}>
                        <NavLink
                            onClick={() => setMenuOpen(false)}
                            activeclassname="active"
                            className={`${item.children && item.children.length > 0 ? 'menu-item-has-children block' : ''} lg:bg-white bg-transparent menu-item text-black text-lg transition-colors no-underline font-bold hover:text-[#9c0a7d] py-1`}
                            to={item.attributes.url}
                            target={`_${item.attributes.target}`}
                        >
                            {item.attributes.title}
                        </NavLink>
                        {item.children && item.children.length > 0 && (
                            <ul className='pl-4 lg:bg-white bg-transparent'>
                                {renderMenuItems(item.children, true)}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    if (loading) {
        return <LoadingMenu />;
    }

    if (error) {
        console.error(error.message);
        return <LoadingMenu />;
    }
    var logoUri = false;
    if(JSON.parse(logo)){
        logoUri = JSON.parse(logo).data.attributes.url;
    }
    return (
        <>
            <div className='bg-white left-0 sticky z-10 top-0 w-screen shadow-md'>
                <nav className='bg-white max-w-inner-content mx-auto my-0 flex flex-row py-4 xl:px-0 px-4 justify-between items-center '>
                    <NavLink className={` my-0 z-20`} to="/" onClick={() => setMenuOpen(false)}>
                        {logoUri && (
                            <img className='w-52' src={`${process.env.REACT_APP_DOMAIN_URI}${logoUri}`} alt='logo' />
                        )}
                        {!logoUri && (
                            <Skeleton className="opacity-70 loading-skeleton lg:!h-[36px]"  width='208px' height='45px' />
                        )}
                    </NavLink>
                    <div className="flex items-center absolute right-4 lg:relative lg:right-0">
                        <button className={`lg:hidden  text-black relative group hamburger-icon ${menuOpen ? 'menu-open' : 'menu-closed'} `} onClick={() => setMenuOpen(!menuOpen)}>
                            <div style={!menuOpen ? { transitionDelay: (data.length * 80) + 'ms' } : { transitionDelay: '0ms' }} className='hamburger-backdrop'></div>
                            <div>
                                <div className={`relative group`}>
                                    <div className={`relative flex  z-40 overflow-hidden items-center justify-center rounded-full scale-75 w-[50px] h-[50px] transform transition-all duration-200 `}>
                                        <div className={`flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden`}>
                                            <div className={`bg-slate-700 h-[2px] w-7 transform transition-all duration-300 origin-left ${menuOpen ? 'translate-y-6' : ''}  delay-100`}></div>
                                            <div className={`bg-slate-700 h-[2px] w-7 rounded transform transition-all duration-300 ${menuOpen ? 'translate-y-6' : ''} delay-75`}></div>
                                            <div className={`bg-slate-700 h-[2px] w-7 transform transition-all duration-300 origin-left ${menuOpen ? 'translate-y-6' : ''}`}></div>
                                            <div className={`absolute items-center justify-between transform transition-all duration-500 top-2.5 ${menuOpen ? 'translate-y-0 w-12' : 'w-0 -translate-x-10'} flex w-0`}>
                                                <div className={`absolute bg-slate-700 h-[2px] w-5 transform transition-all duration-500 delay-300 ${menuOpen ? 'rotate-45' : 'rotate-0'} `}></div>
                                                <div className={`absolute bg-slate-700 h-[2px] w-5 transform transition-all duration-500 delay-300 ${menuOpen ? '-rotate-45' : '-rotate-0'}`}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                    
                    <div className={`${menuOpen ? 'visible' : 'invisible'}  justify-end lg:visible z-10 lg:translate-x-0 lg:px-4 px-0 lg:flex lg:relative w-full lg:w-fit relative rounded-bl-[180px]  lg:h-auto -right-4 lg:right-0 top-full pt-12 bg-transparent lg:pt-0`}>
                        {renderMenuItems(data, false)}

                        <form onSubmit={handleSearch} style={!menuOpen ? { transitionDelay: (data.length * 80 + 100) + 'ms' } : { transitionDelay: '0ms' }} className={`flex ${menuOpen ? 'right-4' : '-right-full'} ${searchOpen ? 'block' : 'lg:hidden'}  duration-300 transition-all items-center ml-4 lg:absolute fixed w-full justify-end lg:bg-white  top-20 lg:right-14 lg:top-1/2 lg:-translate-y-1/2`}>
                            <input
                                type="text"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                placeholder="Zoeken..."
                                className="p-3 border rounded"
                            />
                            <button type="submit" className="btn ml-2">Zoeken</button>
                        </form>

                        <button className="ml-4 hidden lg:block text-black" onClick={() => setSearchOpen(!searchOpen)}>
                            {searchOpen && (
                            <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
                            )}
                            {!searchOpen && (
                            <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 24 24" width="28px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
                            )}
                        </button>
                    </div>
                </nav>
            </div>
            <Outlet />
        </>
    );
};

export default MainMenu;