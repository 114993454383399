// templates/home.js
import React from 'react';
import { ContentBlock } from '../../Blocks/Content';
import { ContentHero } from '../../Blocks/Hero';
import { ContentColumns } from '../../Blocks/Columns';
import PostsSection  from '../../Blocks/PostsSection';
import { Form } from '../../Blocks/Form';
import { ContentButton } from '../../Blocks/Button';
import { ImageSlider } from '../../Blocks/ImageSlider';


export const DynamicContent = ({ data }) => {
    return (
        <div className='DynamicContent  py-14'>
            {data.Content.map((component, index) => {
                switch (component.__typename) {
                    case 'ComponentContentContent':                            
                        return (<ContentBlock key={index} component={component} />);
                    case 'ComponentContentHero':
                        return (<ContentHero key={index} component={component} />);
                    case 'ComponentContentButton':
                        return (<ContentButton key={index} component={component} />);
                    case 'ComponentContentFormulier':
                        return (<Form key={index} component={component} />);
                    case 'ComponentContentColumns':
                        return (<ContentColumns key={index} component={component} />);
                    case 'ComponentContentPosts':
                        return (<PostsSection key={index} component={component} />);
                    case 'ComponentContentSlider':
                        return (<ImageSlider key={index} component={component} />);
                    default:
                        return null;
                }
            })}
        </div>
    );
    
};

