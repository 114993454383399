import { mainQuery } from './mainQuery';

export const fetchPageIds = async (postType) => {
  const queryObject = `
  query {
    ${postType}s {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
  
  `;

  
  const identifier = postType+'s';
  const response = await mainQuery(queryObject);
  return response.data.data[identifier].data;
};

export const fetchComponements = async (postType, slug, previewID = false) => {
  var Id = 0;
  const pageIds = await fetchPageIds(postType);

  
  pageIds.map(async (page) => {
    if (page.attributes.slug === slug) {
      Id = parseInt(page.id);
    }
  });

  if (previewID) Id = parseInt(previewID);
  

  const queryObject = `
    query {
      ${postType}(id: ${Id}) {
        data {
          attributes {
            Title
            categories {
              data {
                attributes {
                  Title
                }
              }
            }
            featuredimage {
              data {
                attributes {
                  url
                  width
                  height
                  alternativeText
                }
              }
            }
            Content {
              __typename
              ... on ComponentContentContent {
                id
                Content
                Background_color
              }
              ... on ComponentContentButton {
                id
                ButtonUrl
                ButtonText
                Styles
                Alignment
                Target
              }
              ... on ComponentContentHero {
                id
                title
                tekst
                images {
                  data {
                    attributes {
                      url
                      width
                      height
                      alternativeText
                    }
                  }
                }
              }
              ... on ComponentContentFormulier{
                id
                Label
                Description
                Submitlabel
                Succesmessage
                Field {
                  Label
                  Type
                  id
                  Placeholder
                  Required
                }
              }
              ... on ComponentContentColumns {
                Amount
                gap
                Column {
                  Content
                  Background_color
                }
                id
              }
              ... on ComponentContentPosts {
                Amount
                id
              }
              ... on ComponentContentSlider {
                id
                Images(pagination: {pageSize: 100}){
                  data {
                    attributes {
                      alternativeText
                      width
                      height
                      url
                    }
                  }
                }
                Rows
                Background
                Loop
                spaceBetween
                slidesPerView
              }
            }
            SEO {
              metaTitle
              metaDescription
              metaImage {
                data {
                  attributes {
                    alternativeText
                    name
                    width
                    height
                    url
                  }
                }
              }
              keywords
              metaRobots
              structuredData
              metaViewport
              canonicalURL
              metaSocial {
                title
                description
                socialNetwork
                image {
                  data {
                    attributes {
                      alternativeText
                      width
                      height
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const response = await mainQuery(queryObject);
  return response.data.data[postType].data.attributes;
};