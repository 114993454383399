import { mainQuery } from './mainQuery';

export const fetchFooterWidgets = async () => {
  const queryObject = `
  query Query {
    footer {
      data {
        attributes {
          Widget {
            Content
          }
        }
      }
    }
  }
  `;
  const response = await mainQuery(queryObject);
  return response.data;
};

export const fetchFooterBottom = async () => {
  const queryObject = `
  query Query {
    footerBottom {
      data {
        attributes {
          Widget {
            Content
          }
        }
      }
    }
  }
  `;

  const response = await mainQuery(queryObject);
  return response.data;
};