import axios from 'axios';

export const formQuery = async ( formName, formData ) => {
  if (!formData) return;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DOMAIN_URI}/api/ezforms/submit`, 
      {
        formName: formName,
        formData: formData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    return undefined; // Return undefined or handle the error as needed
  }
};