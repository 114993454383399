// templates/home.js
import React from 'react';
export const ContentColumns = ({ component }) => {
    if (component.__typename === 'ComponentContentColumns') {                           
        return (
            <div style={{gap: `${component.gap}px`}} className={`contentBlock grid grid-cols-${component.Amount} `} >
                { component.Column.map((column, index) => { 
                    return (
                        <div key={index} style={{backgroundColor: `${column.Background_color}`}} dangerouslySetInnerHTML={{__html: column.Content}} className='p-4' />
                    );
                })}
                
            </div>
        );
    }
};

